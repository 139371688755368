import React, { useState } from "react";
import { Image, Row, Col, Container, Form, Button, InputGroup, FormControl } from "react-bootstrap";
import "./Donate.css";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";

import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";

function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
}

class DonateFeature extends Helper {

    state = {
        inputData: {
            amount:10,
            payment_mode:'card',
        },
        loadingContent: null,
        buttonDisable: false,
        paymentMode: 'card',
    };
      
    handleChange = ({ currentTarget: input }) => {
        const inputData = { ...this.state.inputData };
        inputData[input.name] = input.value;
        this.setState({ inputData });
    };

    handleChangePayment = ({ currentTarget: input }) => {
        const inputData = { ...this.state.inputData };
        inputData['payment_mode'] = input.value;
        this.setState({ paymentMode: input.value,inputData });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loadingContent: this.props.t("loading_text"),
            buttonDisable: true,
        });
        console.log(this.state.inputData);

        if(this.state.paymentMode == "razorpay") {
            this.displayRazorpay();
        } else if (this.state.paymentMode == "paystack") {
            this.paymentPayStackApiCall();
        }else {
            this.paymentApiCall();
        }

    };

    paymentPayStackApiCall = () => {
        api.postMethod("user_donations_via_paystack", this.state.inputData)
        .then((response) => {
            if (response.data.success) {
                ToastDemo(this.props.toastManager, response.data.message, "success");
                this.setState({
                    loadingContentCard: null,
                    buttonDisable: false,
                });
                if (response.data.data.authorization_url) {
                    window.location.href = response.data.data.authorization_url;
                } else {
                    window.location.reload();
                }
            } else {
                ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                );
                this.setState({
                    loadingContentCard: null,
                    buttonDisable: false,
                });
            }
        })
        .catch((error) => {
            ToastDemo(this.props.toastManager, error, "error");
            this.setState({
            loadingContentCard: null,
            buttonDisable: false,
            });
        });
    };


    displayRazorpay = async () => {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    
        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }
    
        const data = await api.postMethod("user_donations_via_razorpay_create_order", this.state.inputData).then(res => res.data);

        // console.log(data);
        if (data.success) {
            const options = {
                key: configuration.get("configData.RAZOR_KEY"),
                currency: data.data.currency,
                amount: data.data.amount.toString(),
                order_id: data.data.id,
                name: 'Donation',
                description: 'Donation',
                handler: async (response) => {
                    try {
                        const inputData = { ...this.state.inputData };
                        inputData['payment_id'] = response.razorpay_payment_id;
                        this.setState({inputData});

                        this.paymentApiCall();
                      
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
            const paymentObject = new window.Razorpay(options)
            paymentObject.open()
        } else {
            ToastDemo(
                this.props.toastManager,
                data.error_messages,
                "error"
            );
            this.setState({
                loadingContentCard: null,
                buttonDisable: false,
            });
        }
        
    }
    paymentApiCall = () => { 

        api
        .postMethod("user_donations_via_card", this.state.inputData)
        .then((response) => {
            if (response.data.success) {
                ToastDemo(this.props.toastManager, response.data.message, "success");
                
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,                
                });
                setTimeout(function() {
                    window.location.reload();
                }, 1000);

            } else {
                ToastDemo(
                    this.props.toastManager,
                    response.data.error_messages,
                    "error"
                );
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                });
            }
        })
        .catch((error) => {
            this.setState({ loadingContent: null, buttonDisable: false });
            ToastDemo(this.props.toastManager, error, "error");
        });

    }

    render() {
        const { inputData,paymentMode } = this.state;  

        const onSuccess = payment => {
            console.log("Success");
            // Congratulation, it came here means everything's fine!

            const inputData = { ...this.state.inputData };
            inputData['payment_id'] = payment.paymentID;
            this.setState({inputData});

            this.paymentApiCall();
            // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
        };

        const onCancel = data => {
            console.log("ERROR");
            // User pressed "cancel" or close Paypal's popup!
            // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
        };

        const onError = err => {
            console.log("ERROR");
            // The main Paypal's script cannot be loaded or somethings block the loading of that script!
            // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
            // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
        };

        let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
        let currency = "USD"; // or you can set this value from your props or state
        let total = inputData.amount; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout

        const client = {
            sandbox: configuration.get("configData.PAYPAL_ID"),
            production: configuration.get("configData.PAYPAL_ID")
        };
        
        return (
            <>
                <div className="donate-feature-sec main padding-top-md">
                    <div className="top-bottom-spacing resp-align-center">
                        <Container>
                            <Row>
                                <Col md={11} lg={10} sm={12} xl={11} className="auto-margin">
                                    <div className="donate-title-sec">
                                        <h2 className="donate-title">{t('donate_now')}</h2>
                                        {/* <p>We are proudly non-profit, non-corporate, </p> */}
                                    </div>
                                </Col>
                            </Row>
                            <div className="donate-box">
                                <Form>
                                    <Row>
                                        <Col md={4}>
                                            <Image
                                                className="donate-left-img"
                                                src={
                                                    window.location.origin + "/assets/img/charity-img.jpg"
                                                }
                                            />
                                            <p>We are proudly non-profit, non-corporate and non-compromised. Thousands of people like you help us stand up for a healthy internet for all. We rely on donations to carry out our mission to keep the Web open and free.<span> <br></br>Will you give Today?</span>
                                            </p>
                                        </Col>
                                        <Col md={8}>
                                            <Form>
                                                <h4>{t('donate_now')}</h4>
                                                
                                                <div className="donate-amount-sec">
                                                    <div className="donate-amount-link">
                                                        {["radio"].map((type) => (
                                                            <div key={`custom-inline-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    custom
                                                                    inline
                                                                    label={t('10')}
                                                                    type={type}
                                                                    id="10"
                                                                    value="10"
                                                                    name="amount"
                                                                    defaultChecked={true}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <Form.Check
                                                                    custom
                                                                    inline
                                                                    label={t('20')}
                                                                    type={type}
                                                                    id="20"
                                                                    value="20"
                                                                    name="amount"
                                                                    onChange={this.handleChange}
                                                                />
                                                                <Form.Check
                                                                    custom
                                                                    inline
                                                                    label={t('50')}
                                                                    type={type}
                                                                    id="50"
                                                                    value="50"
                                                                    name="amount"
                                                                    onChange={this.handleChange}
                                                                />
                                                                <Form.Check
                                                                    custom
                                                                    inline
                                                                    label={t('80')}
                                                                    type={type}
                                                                    id="80"
                                                                    value="80"
                                                                    name="amount"
                                                                    onChange={this.handleChange}
                                                                />
                                                                <Form.Check
                                                                    custom
                                                                    inline
                                                                    label={t('100')}
                                                                    type={type}
                                                                    id="100"
                                                                    value="100"
                                                                    name="amount"
                                                                    onChange={this.handleChange}
                                                                />
                                                               
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="or-divider">
                                                    <h6>Or</h6>
                                                </div>
                                                <div className="donate-amount-text-field">
                                                    <InputGroup className="mb-4">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl
                                                            placeholder="Amount"
                                                            aria-label="Amount"
                                                            aria-describedby="basic-addon1"
                                                            name="amount"
                                                            value={inputData.amount ? inputData.amount : null}
                                                            onChange={this.handleChange}
                                                        />
                                                    </InputGroup>
                                                </div>
                                                <div className="donate-feature-form">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder={t('name')}
                                                            name="name"
                                                            value={inputData.name ? inputData.name : null}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicEmail">
                                                        {/* <Form.Control 
                                                        type="text" 
                                                        placeholder="Last Name" 
                                                        name="last_name"
                                                        value={inputData.last_name ? inputData.last_name : null}
                                                        onChange={this.handleChange}
                                                        /> */}
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Control 
                                                            type="email" 
                                                            placeholder={t('email_address')}
                                                            name="email"
                                                            value={inputData.email ? inputData.email : null}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Control 
                                                            type="number" 
                                                            placeholder={t('mobile_number')}
                                                            name="mobile"
                                                            value={inputData.mobile ? inputData.mobile : null}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control as="textarea" rows={3}  placeholder={t('message')}
                                                        name="message"
                                                        value={inputData.message ? inputData.message : null}
                                                        onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control as="textarea" rows={3}  placeholder={t('description')}
                                                        name="description"
                                                        value={inputData.description ? inputData.description : null}
                                                        onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="donate-choose-payment-mode">
                                                    <h5>Choose Payment</h5>

                                                    <div className="donate-radio-btn">
                                                        {["radio"].map((type) => (
                                                            <div key={`custom-inline-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    custom
                                                                    inline
                                                                    label={t('card')}
                                                                    type={type}
                                                                    id="card"
                                                                    value="card"
                                                                    defaultChecked={true}
                                                                    name="payment_method"
                                                                    onChange={
                                                                        this.handleChangePayment
                                                                    }
                                                                />
                                                                {configuration.get(
                                                                    "configData.PAYPAL_ID"
                                                                ) ? (
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label={t('paypal')}
                                                                        type={type}
                                                                        // id={`custom-inline-${type}-2`}
                                                                        id="paypal"
                                                                        value="paypal"
                                                                        name="payment_method"
                                                                        onChange={
                                                                            this.handleChangePayment
                                                                        }
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {configuration.get("configData.is_razorpay_enabled") == 1 ? (

                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label={t('razorpay')}
                                                                        type={type}
                                                                        id="razorpay"
                                                                        value="razorpay"
                                                                        name="payment_method"
                                                                        onChange={
                                                                            this.handleChangePayment
                                                                        }
                                                                    />

                                                                ) : (
                                                                    ""
                                                                )}
                                                                {configuration.get("configData.is_paystack_enabled") == 1 ? (

                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label={t('paystack')}
                                                                        type={type}
                                                                        id="paystack"
                                                                        value="paystack"
                                                                        name="payment_method"
                                                                        onChange={
                                                                            this.handleChangePayment
                                                                        }
                                                                    />

                                                                ) : (
                                                                    ""
                                                                )}
                                                                <Link
                                                                    to="/add-card"
                                                                    className="float-right btn-link"
                                                                >
                                                                    {t("add_card")}
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="donate-choose-payment-btn-sec">
                                                        
                                                        {paymentMode == "paypal" ? 
                                                            <PaypalExpressBtn
                                                                env={env}
                                                                client={client}
                                                                currency={currency}
                                                                total={total}
                                                                onError={onError}
                                                                onSuccess={
                                                                    onSuccess
                                                                }
                                                                onCancel={onCancel}
                                                            />
                                                        : 
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={
                                                                    this
                                                                        .handleSubmit
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .buttonDisable
                                                                }
                                                            >
                                                                {t("pay_now")}
                                                            </button>
                                                        }
                                                    
                                                    </div>
                                                </div>
                                            </Form>
                                            <div className="donate-amount-sec">
                                                
                                                
                                                <div className="donate-footer-desc-sec">
                                                    <p>UFlix is commited to your privacy: please read our <Link to="#">Privacy Policy.</Link> Your payment details will be processed by
                                                        <Link to="#"> Braintree </Link> a PayPal company (for credit/debit cards) or <Link to="#">PayPal</Link> and a record of your donation will be stored by UFlix</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Container>
                    </div>
                </div>
            </>
        );
    }
};

export default withToastManager(translate(DonateFeature));
