import React from "react";
import { Link } from "react-router-dom";

import Slider from "../SliderView/MainSlider";
import HomePageBanner from "./homePageBanner";
// import ContentLoader from "../Static/contentLoader";
import { apiConstants } from "../Constant/constants";
import Helper from "../Helper/helper";
import { t } from "react-multi-lang";
import HomeLoader from "../Loader/HomeLoader";
import api from "../../Environment";

let inputData = {};

class Genres extends Helper {
    state = {
        maindata: null,
        errorHandle: 0,
        loading: true,
        banner: null,
        loadingHomeSecondSection: true,
        homeSecondData: null,
        loadMoreButtonDisable: false,
        loadingContent: null,
        skipCount: 0,
    };
    checkUrlType(urlType) {
        if (urlType == apiConstants.SERIES) {
            inputData = {
                ...inputData,
                page_type: "SERIES"
            };
        } else if (urlType == apiConstants.KIDS) {
            inputData = {
                ...inputData,
                page_type: "KIDS"
            };
        } else {
            inputData = {
                ...inputData,
                page_type: "FLIMS"
            };
        }
        console.log("active", inputData);
        return inputData;
    }

    componentDidMount() {
        const urlType = this.props.match.params.id;

        inputData = this.checkUrlType(urlType);

        this.homeFirstSection({...inputData , skip : 0});
    }

    componentWillReceiveProps(props) {
        this.setState({ loading: true });
        const urlType = props.match.params.id;

        inputData = this.checkUrlType(urlType);

        this.homeFirstSection(inputData);
    }

    loadMore = (event) => {
        event.preventDefault();
        this.setState({
          loadMoreButtonDisable: true,          
        });

        const urlType = this.props.match.params.id;

        inputData = this.checkUrlType(urlType);

        inputData = {
            ...inputData,
            skip: this.state.skipCount,
        };

        let items;

        api
            .postMethod("home_second_section", inputData)
            .then((response) => {
            if (response.data.success === true) {
                if (this.state.homeSecondData != null) {
                    items = [...this.state.homeSecondData, ...response.data.data];
                } else {
                    items = [...response.data.data];
                }
                this.setState({
                    loadingHomeSecondSection: false,
                    homeSecondData: items,
                    loadMoreButtonDisable: false,
                    skipCount: response.data.total + this.state.skipCount,
                });
            } else {
                let errorHandle = 1;
                this.setState({ errorHandle });
            }
            })
            .catch(function(error) {});
    };

    renderVideoList = (maindata, index) => {
        return (
            <React.Fragment key={index}>
                <div className="main-slidersec">
                    <Link
                        to={{
                            pathname: "/view-all",
                            state: {
                                url_type: maindata.url_type,
                                url_type_id: maindata.url_type_id,
                                page_type: inputData.page_type,
                                title: maindata.title
                            }
                        }}
                    >
                        <h3 className="">
                            {maindata.title}
                            <i className="fas fa-angle-right ml-2" />
                        </h3>
                    </Link>

                    <Slider>
                        {maindata.data.map(movie => (
                            <Slider.Item
                                movie={movie}
                                key={movie.admin_video_id}
                            >
                                item1
                            </Slider.Item>
                        ))}
                    </Slider>
                </div>
            </React.Fragment>
        );
    };

    renderVideoNoData = (maindata, index) => {
        return (
          <React.Fragment key={index}>
            {/* <div className="main-slidersec">
              <h3 className="">
                {maindata.title}
                <i className="fas fa-angle-right ml-2" />
              </h3>
    
              <div className="no-data">
                No Videos Found!!
              </div>
            </div> */}
          </React.Fragment>
        );
      };

    render() {
        const {
            loading,
            maindata,
            banner,
            loadingHomeSecondSection,
            homeSecondData
        } = this.state;

        return (
            <div>
                {loading ? (
                    <HomeLoader />
                ) : (
                    <HomePageBanner banner={banner} />
                )}
                <div className="main p-40">
                    {/* {renderMyList} */}

                    {loading
                        ? ""
                        : maindata.map((mainDa, index) =>
                              mainDa.data.length === 0
                                  ? this.renderVideoNoData(mainDa, index)
                                  : loading
                                  ? t("loading")
                                  : this.renderVideoList(mainDa, index)
                          )}

                    {loadingHomeSecondSection
                        ? ""
                        : homeSecondData.map((mainDa, index) =>
                              mainDa.data.length === 0
                                  ? ""
                                  : loading
                                  ? t("loading")
                                  :  <React.Fragment key={index}>
                                  <div className="main-slidersec">
                                      <Link
                                          to={{
                                              pathname: "/view-all",
                                              state: {
                                                  url_type: mainDa.url_type,
                                                  url_type_id: mainDa.url_type_id,
                                                  page_type: inputData.page_type,
                                                  title: mainDa.title
                                              }
                                          }}
                                      >
                                          <h3 className="">
                                              {mainDa.title}
                                              <i className="fas fa-angle-right ml-2" />
                                          </h3>
                                      </Link>
                  
                                      <Slider>
                                          {mainDa.data.map(movie => (
                                              <Slider.Item
                                                  movie={movie}
                                                  key={movie.admin_video_id}
                                              >
                                                  item1
                                              </Slider.Item>
                                          ))}
                                      </Slider>
                                  </div>
                              </React.Fragment>
                          )}
                     <div>
                        <button
                            className="btn btn-lg"
                            type="button"
                            style={{ position: "absolute", left: "50%", margin: "10px" }}
                            onClick={this.loadMore}
                            disabled={this.state.loadMoreButtonDisable}
                        >
                            {this.state.loadingContent != null
                            ? this.state.loadingContent
                            : t("load_more")}
                        </button>
                    </div>
                    <div className="height-100" />
                   
                </div>
            </div>
        );
    }
}

export default Genres;
