import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Helper from "../../Helper/helper";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import AddCardLoader from "../../Loader/AddCardLoader"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { translate, t } from "react-multi-lang";
import PaymentAddCardModal from "./PaymentAddCardModal";
import configuration from "react-global-configuration";

const AddCardComponent = (props) => {

  var billingImg = {
    backgroundImage: "url(../assets/img/card-image.png)",
  };

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  return (
    <div>
      <div className="main padding-top-md">
        <div className="top-bottom-spacing">
          <div className="row">
            <div className="col-sm-10 col-md-11 col-lg-9 col-xl-8 auto-margin">
              <div className="row m-0">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0">
                  <div className="billing-img relative" style={billingImg}>
                    <div className="view-cards d-none d-md-block do-lg-block d-xl-block">
                      <Link to="/card-details" className="capitalize">
                        <i className="fas fa-chevron-right mr-1" />
                        {t("card_details")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 p-0">
                  <div className="billing-content-sec">
                    <h4 className="billing-head">
                      <i className="far fa-credit-card mr-2" />
                      {t("add_card")}
                    </h4>
                    <p className="grey-line" />
                    
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 top">
                      <button
                        className="btn btn-danger"
                        data-toggle="modal"
                        data-target="#AddCardModel"
                      >
                       {t("add_card")}
                      </button>
                    </div>

                    <Elements stripe={stripePromise}>
                      <PaymentAddCardModal {...props} />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withToastManager(translate(AddCardComponent));
