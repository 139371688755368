import React, { useState } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";
import ToastDemo from "../../Helper/toaster";
import { translate, t } from "react-multi-lang";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const $ = window.$;

const PaymentAddCardModal = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const [addCardButtonDisable, setAddCardButtonDisable] = useState(false);
    const [addCardLoadingContent, setAddCardLoadingContent] = useState(null);

    const addCard = async (ev) => {
        ev.preventDefault();

        setAddCardButtonDisable(true);
        setAddCardLoadingContent("Loading... Please wait");
        if (stripe) {
            await stripe
                .createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardElement),
                })
                .then((payload) => {
                    const inputData = {
                        card_token: payload.paymentMethod.id,
                    };
                    api
                        .postMethod("payment_card_add", inputData)
                        .then(response => {
                            if (response.data.success === true) {
                                ToastDemo(
                                    props.toastManager,
                                    response.data.message,
                                    "success"
                                );
                                $("#AddCardModel").modal("hide");
                                window.location = "/card-details";
                            } else {
                                setAddCardButtonDisable(false);
                                setAddCardLoadingContent(null);
                                ToastDemo(
                                    props.toastManager,
                                    response.data.error,
                                    "error"
                                );
                            }
                        })
                        .catch(error => {
                            setAddCardButtonDisable(false);
                            setAddCardLoadingContent(null);
                            ToastDemo(props.toastManager, error, "error");
                        });
                })
                .catch(error => {
                    setAddCardButtonDisable(false);
                    setAddCardLoadingContent(null);
                    ToastDemo(
                        props.toastManager,
                        "Please check your card details and try again..",
                        "error"
                    );
                });
        } else {
            setAddCardButtonDisable(false);
            setAddCardLoadingContent(null);
            ToastDemo(props.toastManager, "Stripe is not configured", "error");
        }
    };

    // const handleChange = ({ currentTarget: input }) => {
    //   const formaData = { ...this.state.formData };
    //   formaData[input.name] = input.value;
    //   this.setState({ formaData });
    // };

    return (
        <div className="modal fade" id="AddCardModel">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">
                            &times;
                        </button>
                    </div>

                    <div className="modal-body">
                        <form onSubmit={addCard}>
                            <CardElement />
                            <div className="text-right">

                                <button
                                    type="submit"
                                    className="btn btn-danger btn-block mt-4"
                                    disabled={addCardButtonDisable}
                                >
                                    {addCardLoadingContent != null ? addCardLoadingContent : t("add_card")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default translate(PaymentAddCardModal);
