import React from "react";
import { Link } from "react-router-dom";
import Slider from "../SliderView/MainSlider";
import HomePageBanner from "./homePageBanner";
import ContentLoader from "../Static/contentLoader";
import Helper from "../Helper/helper";
import { t } from "react-multi-lang";
import api from "../../Environment";

let inputData = {};

class Category extends Helper {
    state = {
        maindata: null,
        errorHandle: 0,
        loading: true,
        banner: null,
        loadingHomeSecondSection: true,
        homeSecondData: null,
        loadMoreButtonDisable: false,
        skipCount: 0,
    };

    componentDidMount() {
        inputData = {
            ...inputData,
            page_type: "CATEGORY",
            sub_page_type: "SUB_CATEGORY",
            category_id: this.props.match.params.id,
            skip: this.state.skipCount,
        };

        this.homeFirstSection(inputData);
    }

    componentWillReceiveProps(props) {
        this.setState({ loading: true });

        inputData = {
            ...inputData,
            page_type: "CATEGORY",
            sub_page_type: "SUB_CATEGORY",
            category_id: props.match.params.id,
            skip: this.state.skipCount
        };

        this.homeFirstSection(inputData);
    }

    renderVideoList = (maindata, index) => {
        return (
            <React.Fragment key={index}>
                <div className="main-slidersec">
                    <Link
                        to={{
                            pathname: "/view-all",
                            state: {
                                url_type: maindata.url_type,
                                url_type_id: maindata.url_type_id,
                                page_type: inputData.page_type,
                                category_id: inputData.category_id,
                                title: maindata.title
                            }
                        }}
                    >
                        <h3 className="">
                            {maindata.title}
                            <i className="fas fa-angle-right ml-2" />
                        </h3>
                    </Link>

                    <Slider>
                        {maindata.data.map(movie => (
                            <Slider.Item
                                movie={movie}
                                key={movie.admin_video_id}
                            >
                                item1
                            </Slider.Item>
                        ))}
                    </Slider>
                </div>
            </React.Fragment>
        );
    };

    renderVideoNoData = (maindata, index) => {
        return (
          <React.Fragment key={index}>
            {/*<div className="main-slidersec">
              <h3 className="">
                {maindata.title}
                <i className="fas fa-angle-right ml-2" />
              </h3>
    
              <div className="no-data">
                No Videos Found!!
              </div>
            </div>*/}
          </React.Fragment>
        );
      };
    
    loadMore = (event) => {
        event.preventDefault();
        this.setState({
          loadMoreButtonDisable: true,          
        });

        inputData = {
            ...inputData,
            page_type: "CATEGORY",
            category_id: this.props.match.params.id,
            skip: this.state.skipCount,
        };

        let items;

        api
            .postMethod("home_second_section", inputData)
            .then((response) => {
            if (response.data.success === true) {
                if (this.state.homeSecondData != null) {
                    items = [...response.data.data];
                } else {
                    items = [...response.data.data];
                }
                this.setState({
                    loadingHomeSecondSection: false,
                    homeSecondData: items,
                    loadMoreButtonDisable: false,
                    //skipCount: response.data.data.length + this.state.skipCount,
                });
            } else {
                let errorHandle = 1;
                this.setState({ errorHandle });
            }
            })
            .catch(function(error) {});
    };

    render() {
        const {
            loading,
            maindata,
            banner,
            loadingHomeSecondSection,
            homeSecondData
        } = this.state;

        return (
            <div>
                {loading ? (
                    <ContentLoader />
                ) : (
                    <HomePageBanner banner={banner} />
                )}
                <div className="main p-40">
                    {/* {renderMyList} */}

                    {loading
                        ? ""
                        : maindata.map((mainDa, index) =>
                              mainDa.data.length === 0
                                  ? this.renderVideoNoData(mainDa, index)
                                  : loading
                                  ? t("loading")
                                  : this.renderVideoList(mainDa, index)
                          )}

                        {loadingHomeSecondSection
                        ? ""
                        : homeSecondData.map((mainDa, index) =>
                              mainDa.data.length === 0
                                  ? ""
                                  : loading
                                  ? t("loading")
                                  :  <React.Fragment key={index}>
                                  <div className="main-slidersec">
                                      <Link
                                          to={{
                                              pathname: "/view-all",
                                              state: {
                                                  url_type: mainDa.url_type,
                                                  url_type_id: mainDa.url_type_id,
                                                  page_type: inputData.sub_page_type,
                                                  title: mainDa.title
                                              }
                                          }}
                                      >
                                          <h3 className="">
                                              {mainDa.title}
                                              <i className="fas fa-angle-right ml-2" />
                                          </h3>
                                      </Link>
                  
                                      <Slider>
                                          {mainDa.data.map(movie => (
                                              <Slider.Item
                                                  movie={movie}
                                                  key={movie.admin_video_id}
                                              >
                                                  item1
                                              </Slider.Item>
                                          ))}
                                      </Slider>
                                  </div>
                              </React.Fragment>
                          )}
                     <div>
                        <button
                            className="btn btn-lg"
                            type="button"
                            style={{ position: "absolute", left: "50%", margin: "10px" }}
                            onClick={this.loadMore}
                            disabled={this.state.loadMoreButtonDisable}
                        >
                            {this.state.loadingContent != null
                            ? this.state.loadingContent
                            : t("load_more")}
                        </button>
                    </div>

                    <div className="height-100" />
                </div>
            </div>
        );
    }
}

export default Category;
